import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private router = inject(Router);
  private loggedUser?: string;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private refreshingInProgress: boolean = false;

  private http = inject(HttpClient);
  private storageService = inject(StorageService);
  constructor() { }

  public login(user: {email: string, password: string}) : Observable<any> {
    return this.http.post(environment.API_PATH + '/auth/client', user).pipe(
      tap((tokens: any) => this.doLoginUser(user.email, JSON.stringify(tokens))),
    )
  }

  public isLoggedIn(): boolean {
    // if (!!localStorage.getItem(this.JWT_TOKEN) && !this.isRefreshTokenExpired()) {
    //   this.storageService.$authEvent.next(true);
    //   return true;
    // } else {
    //   return false;
    // }

    return !!localStorage.getItem(this.JWT_TOKEN) && !this.isRefreshTokenExpired();
  }

  private doLoginUser(email: string, tokens: any) {
    this.loggedUser = email;
    localStorage.setItem('user', email);
    this.storeJwtToken(tokens);
    // const tokens = localStorage.getItem('JWT_TOKEN');
    // TODO: duplicate
    if (tokens) {
      const token: any = JSON.parse(tokens).accessToken;
      const decoded: any = jwtDecode(token);
      this.storageService.isAdmin = decoded.admin;
      console.log('ngOnInit decoded', decoded);
      localStorage.setItem('user_id', decoded.sub);
    }
    this.isAuthenticatedSubject.next(true);
    this.storageService.$authEvent.next(true);
  }

  private storeJwtToken(jwt: any) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  public logout() {
    localStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['/login'])
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  isAccessTokenExpired() {
    const tokens = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return true;
    const token = JSON.parse(tokens).accessToken;
    const decoded = jwtDecode(token);
    if (!decoded.exp) return true;
    const expirationDate = decoded.exp * 1000;
    const now = new Date().getTime();

    return expirationDate < now;
  }

  isRefreshTokenExpired() {
    const tokens = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return true;
    try {
      const token = JSON.parse(tokens).refreshToken;
      const decoded = jwtDecode(token);
      if (!decoded.exp) return true;
      const expirationDate = decoded.exp * 1000;
      const now = new Date().getTime();

      return expirationDate < now;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  refreshToken() {
    let tokens: any = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return;
    tokens = JSON.parse(tokens);
    const refreshToken = tokens.refreshToken;
    return this.http
      .post<any>(environment.API_PATH + '/auth/client-refresh-token', {
        refreshToken,
        // email: this.loggedUser,
        email: localStorage.getItem('user')
      })
      .pipe(tap((tokens: any) => this.storeJwtToken(JSON.stringify(tokens))));
  }

  public getCurrentUser() {
    // return this.http.get('https://api.escuelajs.co/api/v1/auth/profile');
    return '';
  }
}
